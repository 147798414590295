<!-- Copyright 2022, Common Good Learning Tools LLC -->
<template><div>
	<h3>Create a Sandbox Copy</h3>

    <p>A sandbox is a testing environment where users can make edits, create crosswalks, or prototype a new hierarchy while maintaining the original framework. This is especially useful when reviewing and revising a public-facing framework. While edits are made to the Sandbox, the original framework will remain unchanged until the Sandbox is merged, updating the original framework to match the Sandbox.</p>

    <p>To create a Sandbox copy of a framework:
        <ul class="k-no-clear">
            <li><span v-html="link('signing_in', 'Sign in')"></span> to <span v-html="app_name"/> with Framework / System Admin or Editor permissions (see <span v-html="link('admin', 'Admin Functions')"></span>).</li>
            <img srcset="/docimages/create_sandbox_copy.png 2x" class="k-help-img float-right">
            <li>Select the framework</li>
            <li>Enable <strong>edit mode</strong> by clicking the <nobr>button <v-icon small>fas fa-edit</v-icon></nobr> <strong>Edit</strong> icon from the framework toolbar</li>
            <li>From the document card, choose the <strong>Edit Framework</strong> button.</li>
            <li>From the <strong>edit menu</strong>, select <strong>MORE OPTIONS</strong> </li>
            <li>Choose <strong>CREATE A SANDBOX COPY OF THIS FRAMEWORK</strong></li>
        </ul>
    </p>

    <p>
        <ul class="k-no-clear">
            <li>A pop-up prompt will appear - choose to <strong>Remain in Original</strong> or <strong>Open Sandbox</strong>.
                <ul>
                    <li><strong>NOTE:</strong> Choosing Remain in Original will save the Sandbox Copy with the same name as the original framework. Return to the Framework Index page to locate the filter <strong>SHOW SANDBOXES</strong> checkbox and the <nobr>button <v-icon small>fa-solid fa-umbrella-beach</v-icon></nobr> Sandbox icon next to the framework title.</li>
                    <br>
                    <img srcset="/docimages/framework_index_sand.png 3x" class="k-help-img block">
                </ul>
            </li>

            <br>

            <li>Make the edits, associations, crosswalk, and changes in organization using the same functionality as the original framework.</li>
            <img srcset="/docimages/apply_sandbox_changes.png 2x" class="k-help-img float-right">
            <li>Decide if the changes made to the Sandbox should be applied to the Original Framework or discarded entirely:</li>
            <ul>
                <li>To apply the changes from the Sandbox to the Original Framework, click <strong>EDIT FRAMEWORK</strong> button in the document card, select <strong>MORE OPTIONS > APPLY SANDBOX CHANGES TO ORIGINAL FRAMEWORK</strong>. This will apply the sandbox copy of the framework and all revisions made within it to overwrite the original framework (GUIDs will not be changed).
                    <ul>   
                        <li>A window will appear.</li>
                        <li>Check the box <strong>Delete sandbox after applying changes</strong> to merge the Sandbox with the original and delete the Sandbox. Leave the box unchecked to leave the Sandbox in the index.</li>
                    </ul> 
            
                </li>
            
                <li><strong>DELETE FRAMEWORK…</strong> will delete the sandbox copy of the framework and all revisions made within it without applying any changes to the original framework.</li>
            </ul>
        </ul>
    </p>

    <br>

    <p><strong>BEST PRACTICES</strong>
      <ul>
        <li>Before creating a Sandbox, use the <span v-html="link('archives', 'Manage Framework Archives')"></span> to stamp an archive to save a version of the framework. This way, whether simply playing in the sandbox, or creating revisions to merge later, a stamped archive can easily and quickly be reverted.</li>
        <li>Once a Sandbox copy of a framework is created, no additional edits should be made to the original (if the plan is to later apply the sandbox changes to the original). <strong>Creating a Sandbox, making changes to the original, and then merging the Sandbox to the Original will result in errors</strong></li>
        <li>When a Sandbox copy of a framework is created, rename it so users and reviewers can quickly find and differentiate the Sandbox from the Original Framework.
            <ul>
                <li>Add a date to the title of the Sandbox copy framework to remind users of when the sandbox was made.</li>
            </ul>
        </li>
        <li>The Sandbox copy of a framework inherits the user permissions of the original framework at the time it was created. To add or remove Sandbox user access, use the kebab menu within the framework and the <span v-html="link('manage_users', ' Manage Users guide')"></span>.
            <ul>
                <li>Once the Sandbox copy is deleted or the changes are applied, any added or revised Sandbox user access does not carry over to the original framework.</li>
            </ul>
        </li>
      </ul>  
    </p>

</div></template>

<script>
	import HelpMixin from './HelpMixin'
	export default {
		mixins: [HelpMixin],
	}
</script>

<style lang="scss">
</style>
	